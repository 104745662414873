:root {
  --modal-background: var(--white);
  --modal-header-background: var(--gray--inactive);
  --modal-header-text: var(--text--1);
  --modal-alt-header-background: var(--white);
  --modal-alt-header-text: var(--text--1);
  --modal-border-radius: 0.25rem;
  --modal-footer-border-color: var(--gray--border);
  --modal-footer-border-width: 2px;
  --modal-panel-header-background: var(--primary--highlight);
  --modal-alt-panel-header-background: var(--gray--inactive);
  --modal-panel-header-text: var(--white);
  --modal-alt-panel-header-text: var(--text--1);
  --modal-error: var(--error);
  --modal-flyout-width: 31.5rem;
  --modal-popup-width: 27rem;
  --modal-large-width: 38.625rem;
  --modal-extra-large-width: 42.5rem;
}

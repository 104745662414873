// stylelint-disable plugin/selector-bem-pattern
:root {
  --btn-size__min-width: 6.5rem;
  --btn-size__height: 2.5rem;
  --btn-size__padding--x: 1.5rem;
  --btn-border__radius--top--left: 0.25rem;
  --btn-border__radius--top--right: 0.25rem;
  --btn-border__radius--bottom--right: 0.25rem;
  --btn-border__radius--bottom--left: 0.25rem;
  --btn-border__width: 1px;
  --btn-disabled__text: var(--text--2);
  --btn-disabled__background: var(--gray--border);
  --btn-disabled__border: var(--gray--border);
  --btn-disabled__icon: var(--text--2);
  --btn-focus__color: var(--secondary--3);
  --btn-focus__width: 4px;
  --btn-font__size: 1rem;
  --btn-font__weight: var(--t-bold-font-weight);
  --btn-font__line-height: 1.5rem;
  --btn-font__family: var(--typeface-1-name);
  --btn-special__background--resting: var(--secondary--3);
  --btn-special__background--hover: var(--text--1);
  --btn-special__border--resting: var(--secondary--3);
  --btn-special__border--hover: var(--text--1);
  --btn-special__icon--resting: var(--text--1);
  --btn-special__icon--hover: var(--white);
  --btn-special__text--resting: var(--text--1);
  --btn-special__text--hover: var(--white);
  --btn-high__background--resting: var(--primary);
  --btn-high__background--hover: var(--text--1);
  --btn-high__border--resting: var(--primary);
  --btn-high__border--hover: var(--text--1);
  --btn-high__icon--resting: var(--white);
  --btn-high__icon--hover: var(--white);
  --btn-high__text--resting: var(--white);
  --btn-high__text--hover: var(--white);
  --btn-medium__background--resting: var(--white);
  --btn-medium__background--hover: var(--primary--tint);
  --btn-medium__border--resting: var(--primary);
  --btn-medium__border--hover: var(--primary);
  --btn-medium__icon--resting: var(--primary);
  --btn-medium__icon--hover: var(--primary);
  --btn-medium__text--resting: var(--primary);
  --btn-medium__text--hover: var(--primary);
  --btn-moderate__background--resting: var(--transparent);
  --btn-moderate__background--hover: var(--white);
  --btn-moderate__border--resting: var(--white);
  --btn-moderate__border--hover: var(--white);
  --btn-moderate__icon--resting: var(--white);
  --btn-moderate__icon--hover: var(--white);
  --btn-moderate__text--resting: var(--white);
  --btn-moderate__text--hover: var(--primary);
  --btn-low__background--resting: var(--transparent);
  --btn-low__background--hover: var(--transparent);
  --btn-low__border--resting: var(--gray--shadow);
  --btn-low__border--hover: var(--text--2);
  --btn-low__icon--resting: var(--text--2);
  --btn-low__icon--hover: var(--text--2);
  --btn-low__text--resting: var(--text--2);
  --btn-low__text--hover: var(--text--2);
  --btn-error__background--resting: var(--transparent);
  --btn-error__background--hover: var(--error--tint);
  --btn-error__border--resting: var(--error);
  --btn-error__border--hover: var(--error);
  --btn-error__icon--resting: var(--error);
  --btn-error__icon--hover: var(--error);
  --btn-error__text--resting: var(--error);
  --btn-error__text--hover: var(--error);
  --btn-text__icon--resting: var(--primary);
  --btn-text__icon--hover: var(--primary);
  --btn-text__text--resting: var(--primary);
  --btn-text__text--hover: var(--primary);
}

:root {
  --white: #fff;
  --black: #000;
  --transparent: rgba(255, 255, 255, 0);

  --primary--highlight: #1a469f;
  --primary: #2567ed;
  --text--1: #0c2250;
  --text--2: #667592;
  --gray--artboard: #fdfdfd;
  --gray--inactive: #f8f9fa;
  --gray--border: #eceef0;
  --gray--shadow: #c1c8d5;
  --gray--jet: #3f3e3e;
  --secondary--1: #e02271;
  --secondary--2: #fd6240;
  --secondary--3: #fdca40;
  --secondary--4: #2bcba7;
  --secondary--5: #6fd5ee;
  --secondary--6: #446ec2;
  --success: #1dc291;
  --warn: #fdca40;
  --error: #e10b30;
  --primary--tint: #f4f8ff;
  --notification--1--tint: #fff7f6;
  --notification--2--tint: #fef6ec;
  --success--tint: #f4fcf9;
  --warn--tint: #fef6ec;
  --error--tint: #fef3f5;
  --text--mix: #163b87;
  --text--mix--2: #102e70;
  --mask: rgba(15, 14, 14, 0.8);
  --mask--white: rgba(255, 255, 255, 0.95);
  --link: #2567ed;
}

.icon--pointer,
.icon--link {
  cursor: pointer;
}

.icon--move {
  cursor: move;
}

.icon--rotate90 {
  transform: rotate(90deg);
}

.icon--rotate180 {
  transform: rotate(180deg);
}

.icon--flip {
  transform: scaleX(-1);
}

.icon {
  display: inline-block;
  transition: opacity var(--transition-timing) ease,
    transform var(--transition-timing) ease, color var(--transition-timing) ease;
  font-family: 'System Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  &.is-disabled {
    pointer-events: none;
    opacity: var(--icon-disabled-opacity);
  }
}

.icon--small {
  font-size: var(--icon-system-small);
  height: var(--icon-system-small);
  width: var(--icon-system-small);
}

.icon--medium {
  font-size: var(--icon-system-medium);
  height: var(--icon-system-medium);
  width: var(--icon-system-medium);
}

.icon--large {
  font-size: var(--icon-system-large);
  height: var(--icon-system-large);
  width: var(--icon-system-large);
}

.icon--product {
  font-family: 'Product Icons';

  &.icon--small {
    font-size: var(--icon-product-small);
    height: var(--icon-product-small);
    width: var(--icon-product-small);
  }

  &.icon--medium {
    font-size: var(--icon-product-medium);
    height: var(--icon-product-medium);
    width: var(--icon-product-medium);
  }

  &.icon--large {
    font-size: var(--icon-product-large);
    height: var(--icon-product-large);
    width: var(--icon-product-large);
  }
}

.icon--primary,
.icon--link {
  color: var(--primary);
  fill: var(--primary);
}

.icon--alt {
  color: var(--text--2);
  fill: var(--text--2);
}

.icon--success {
  color: var(--success);
  fill: var(--success);
}

.icon--warn {
  color: var(--warn);
  fill: var(--warn);
}

.icon--error {
  color: var(--error);
  fill: var(--error);
}

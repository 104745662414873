:root {
  --tabs-header-border-width: 1px;
  --tabs-header-border-color: var(--gray--shadow);
  --tabs-indicator-border-width: 3px;
  --tabs-indicator-border-color: var(--transparent);
  --tabs-inactive-text-color: var(--text--2);
  --tabs-active-border-color: var(--primary);
  --tabs-active-text-color: var(--primary);
  --tabs-active-font-weight: var(--heavy);
}

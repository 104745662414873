:root {
  --tooltip-background: rgba(0, 0, 0, 0.9);
  --tooltip-border-radius: 0.25rem;
  --tooltip-font-size: var(--t-caption-font-size);
  --tooltip-line-height: var(--t-caption-line-height);
  --tooltip-font-weight: var(--regular);
  --tooltip-letter-spacing: 0.25px;
  --tooltip-text: var(--white);
  --tooltip-min-width: 5rem;
  --tooltip-max-width: 21rem;
  --tooltip-z-index: 100;
  --tooltip-left: unset;
  --tooltip-right: unset;
}

:root {
  --layer-under: 0;
  --layer-base: 1000;
  --layer-append: 2000;
  --layer-navigation: 3000;
  --layer-mask: 4000;
  --layer-modal: 5000;
  --layer-fixed: 6000;
  --layer-info: 7000;
  --layer-feedback: 10000;
}

@import '../mixins/responsive';
/* stylelint-disable plugin/selector-bem-pattern */

:root {
  --nav-top-background: var(--text--mix);
  --nav-top-height: 2.5rem;
  --nav-top-dropdown-background: var(--text--mix--2);
  --nav-top-dropdown-background-hover: var(--text--1);
  --nav-top-dropdown-background-active: var(--text--1);
  --nav-background: var(--primary--highlight);
  --nav-box-shadow: var(--card-shadow);
  --nav-link-background: var(--primary--highlight);
  --nav-link-background-hover: var(--text--1);
  --nav-link-background-active: var(--text--1);
  --nav-link-border-color: var(--transparent);
  --nav-link-border-width: 0;
  --nav-link-color: var(--white);
  --nav-link-color-hover: var(--white);
  --nav-link-color-active: var(--white);
  --nav-link-font-weight: var(--regular);
  --nav-link-font-weight-hover: var(--regular);
  --nav-link-font-weight-active: var(--heavy);
  --nav-logo: url(https://d32ul9oyxvd2n5.cloudfront.net/assets/logo.svg);
  --nav-logo-height: 35px;
  --nav-logo-width: 115px;
  --nav-co-brand-logo: ''; // the co brand logo is empty by default unless configured so thats why it is empty here
  --nav-co-brand-logo-height: 35px;
  --nav-co-brand-logo-width: 115px;
  --nav-menu-toggle-color: var(--white);
  --nav-icon-background: var(--text--mix);
  --nav-icon-background-active: var(--text--1);
  --nav-icon-background-hover: var(--text--1);
  --nav-icon-color: var(--white);
  --nav-icon-color-hover: var(--white);
  --nav-icon-color-active: var(--white);
  --nav-total-height: var(--nav-height);
  --nav-border-width: 0;
  --nav-border-color: var(--transparent);

  @include responsive(large) {
    --nav-total-height: calc(var(--nav-height) + var(--nav-top-height));
  }

  --outlet-height: calc(100vh - var(--nav-total-height));
}

:root {
  --thin: 100;
  --extra-light: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;
  --extra-bold: 800;
  --heavy: 900;
}

:root {
  --base-font-size: 1rem;
  --typeface-1-name: 'Lato', 'Helvetica Neue', sans-serif;
  --typeface-1-url: 'https://fonts.googleapis.com/css?family=Lato:400,900';
  --t-mobile-header-font-size: 1.5rem;
  --t-mobile-header-line-height: 1.875rem;
  --t-mobile-subheader-font-size: 1.25rem;
  --t-mobile-subheader-line-height: 1.5625rem;
  --h1-font-family: var(--typeface-1-name);
  --h1-font-size: 2.625rem;
  --h1-line-height: 3.25rem;
  --h1-font-color: var(--text--1);
  --h1-font-weight: var(--regular);
  --h2-font-family: var(--typeface-1-name);
  --h2-font-size: 2.25rem;
  --h2-line-height: 2.8125rem;
  --h2-font-color: var(--text--1);
  --h2-font-weight: var(--regular);
  --h3-font-family: var(--typeface-1-name);
  --h3-font-size: 2rem;
  --h3-line-height: 2.5rem;
  --h3-font-color: var(--text--1);
  --h3-font-weight: var(--regular);
  --h4-font-family: var(--typeface-1-name);
  --h4-font-size: 1.75rem;
  --h4-line-height: 2.1875rem;
  --h4-font-color: var(--text--1);
  --h4-font-weight: var(--regular);
  --h5-font-family: var(--typeface-1-name);
  --h5-font-size: 1.5rem;
  --h5-line-height: 1.875rem;
  --h5-font-color: var(--text--1);
  --h5-font-weight: var(--regular);
  --h6-font-family: var(--typeface-1-name);
  --h6-font-size: 1.25rem;
  --h6-line-height: 1.5625rem;
  --h6-font-color: var(--text--1);
  --h6-font-weight: var(--regular);
  --t-title-font-size: 1.125rem;
  --t-title-line-height: 1.6875rem;
  --t-title-font-family: var(--typeface-1-name);
  --t-data-font-size: 1rem;
  --t-data-line-height: 1.5rem;
  --t-data-font-family: var(--typeface-1-name);
  --t-caption-font-size: 0.75rem;
  --t-caption-line-height: 1.125rem;
  --t-caption-font-family: var(--typeface-1-name);
  --t-caps-letter-spacing: 0.6px;
  --t-bold-font-weight: var(--heavy);
  --t-link-font-weight: var(--heavy);
  --t-link-text-decoration: 'none';
  --ui-title-font-color: var(--text--1);
  --ui-title-font-family: var(--typeface-1-name);
  --ui-title-font-weight: var(--t-bold-font-weight);
  --ui-title-font-size-desktop: var(--h4-font-size);
  --ui-title-line-height-desktop: var(--h4-line-height);
  --ui-title-1-font-color: var(--text--1);
  --ui-title-1-font-family: var(--typeface-1-name);
  --ui-title-1-font-weight: var(--t-bold-font-weight);
  --ui-title-1-font-size-desktop: var(--h4-font-size);
  --ui-title-1-line-height-desktop: var(--h4-line-height);
  --ui-header-color: var(--text--1);
  --ui-header-font-family: var(--typeface-1-name);
  --ui-header-font-weight: var(--t-bold-font-weight);
  --ui-header-font-size-desktop: var(--h4-font-size);
  --ui-header-line-height-desktop: var(--h4-line-height);
  --ui-subheader-font-color: var(--text--1);
  --ui-subheader-font-family: var(--typeface-1-name);
  --ui-subheader-font-weight: var(--t-bold-font-weight);
  --ui-subheader-font-size-desktop: var(--h6-font-size);
  --ui-subheader-line-height-desktop: var(--h6-line-height);
  --ui-card-header-1-color: var(--text--1);
  --ui-card-header-1-font-family: var(--typeface-1-name);
  --ui-card-header-1-font-weight: var(--t-bold-font-weight);
  --ui-card-header-1-font-size-desktop: var(--h4-font-size);
  --ui-card-header-1-line-height-desktop: var(--h4-line-height);
  --ui-card-header-2-color: var(--primary);
  --ui-card-header-2-font-family: var(--typeface-1-name);
  --ui-card-header-2-font-weight: var(--t-bold-font-weight);
  --ui-card-header-2-font-size-desktop: var(--h6-font-size);
  --ui-card-header-2-line-height-desktop: var(--h6-line-height);
  --ui-description-color: var(--text--1);
  --ui-description-font-family: var(--typeface-1-name);
  --ui-description-font-weight: var(--regular);
  --ui-description-font-size-desktop: var(--t-data-font-size);
  --ui-description-line-height-desktop: var(--t-data-line-height);
}

:root {
  --icon-source-system: 'https://d32ul9oyxvd2n5.cloudfront.net/icons/system-icons.woff';
  --icon-source-product: 'https://d32ul9oyxvd2n5.cloudfront.net/icons/product-icons.woff';
  --icon-color: var(--text--1);
  --icon-disabled-opacity: 0.5;
  --icon-system-small: 1.125rem;
  --icon-system-medium: 1.5rem;
  --icon-system-large: 3rem;
  --icon-product-small: 1.5rem;
  --icon-product-medium: 1.875rem;
  --icon-product-large: 3rem;
}

@import '../../../libs/design-system/assets/styles/base/base';
@import '../../../libs/design-system/assets/styles/mixins/_focus';

@font-face {
  font-family: 'System Icons';
  font-weight: var(--regular);
  font-style: normal;
  src: url('https://d32ul9oyxvd2n5.cloudfront.net/icons/system-icons.woff')
    format('woff');
}

@font-face {
  font-family: 'Product Icons';
  font-weight: var(--regular);
  font-style: normal;
  src: url('https://d32ul9oyxvd2n5.cloudfront.net/icons/product-icons.woff')
    format('woff');
}

/* will only be hidden when angular can import styles.scss */
.hideIfAngular {
  display: none;
}

.showIfAngularLoaded {
  display: block;
}

*:focus {
  @include focus;
}

.cx-messenger {
  position: relative;
  z-index: var(--layer-mask);
}

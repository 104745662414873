:root {
  // TODO: configurable box-shadows
  --card-shadow: 0 2px 4px 0 var(--gray--shadow);
  --footer-shadow: 0 0 4px 0 var(--gray--shadow);
  --panel-shadow: 2px 4px 0 var(--gray--shadow);

  --transition-timing: 236ms;
  --transition-timing--slow: 472ms;
  --transition-timing--fast: 118ms;
}

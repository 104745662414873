:root {
  --fc-height: 2.5rem;
  --fc-padding-y: 0.5rem;
  --fc-padding-x: 1rem;
  --fc-font-size: 1rem;
  --fc-line-height: 1.5rem;
  --fc-background: var(--white);
  --fc-border-color: var(--gray--shadow);
  --fc-border-width: 1px;
  --fc-border-radius: 0.25rem;
  --fc-hover-background: var(--white);
  --fc-hover-border: var(--primary);
  --fc-focus-background: var(--white);
  --fc-focus-border: var(--primary);
  --fc-active-background: var(--white);
  --fc-active-border: var(--primary);
  --fc-disabled-text: var(--text--1);
  --fc-disabled-icon: var(--text--1);
  --fc-disabled-background: var(--gray--border);
  --fc-disabled-border: var(--gray--border);
  --fc-error-background: var(--error--tint);
  --fc-error-border: var(--error);
  --fc-dropdown-icon: 'carat_down';
  --fc-dropdown-triangle: var(--text--1);
  --fc-checkbox-radio-width: 1.25rem;
  --fc-checkbox-radio-height: 1.25rem;
  --fc-checkbox-radio-border-width: 2px;
  --fc-checkbox-radio-border-color: var(--text--1);
  --fc-checkbox-radio-disabled-background: var(--gray--shadow);
  --fc-checkbox-checked-border: var(--primary);
  --fc-checkbox-checked-background: var(--primary);
  --fc-checkbox-checked-color: var(--white);
  --fc-radio-checked-border: var(--primary);
  --fc-radio-checked-background: var(--white);
  --fc-radio-checked-color: var(--primary);
  --fc-radio-checked-height: 0.5rem;
  --fc-radio-checked-width: 0.5rem;
  --fc-menu-height: 12.5rem;
  --fc-menu-background: var(--white);
  --fc-menu-border-radius: 0.25rem;
  --fc-menu-option-hover: var(--gray--inactive);
  --fc-menu-option-highlight: var(--primary);
  --fc-textarea-height: 10rem;
  --fc-icon-color: var(--text--2);
  --switch-width: 5.625rem;
  --switch-height: 2.5rem;
  --switch-border-radius: 1.25rem;
  --switch-inactive-background: var(--gray--border);
  --switch-active-background: var(--success);
  --switch-toggle-radius: 1.25rem;
  --switch-toggle-gap: 0.25rem;
  --switch-toggle-size: 2rem;
  --switch-toggle-inactive-background: var(--text--2);
  --switch-toggle-active-background: var(--white);
  --switch-small-width: 2.8125rem;
  --switch-small-height: 1.25rem;
  --switch-small-toggle-size: 1rem;
  --switch-small-toggle-gap: 0.125rem;
  --toggle-width-mobile: 6.25rem;
  --toggle-width-tablet: 7rem;
  --toggle-height: 2.5rem;
  --toggle-border-width: 1px;
  --toggle-border-color: var(--gray--shadow);
  --toggle-border-radius: 0.25rem;
  --toggle-font-size: 1rem;
  --toggle-line-height: 1.5rem;
  --toggle-active-background: var(--text--1);
  --toggle-active-border: var(--text--1);
  --toggle-active-text-color: var(--white);
  --toggle-active-font-weight: var(--heavy);
  --toggle-disabled-text-color: var(--gray--shadow);
  --toggle-disabled-active-background: var(--gray--border);
  --toggle-disabled-active-text-color: var(--black);
  --toggle-disabled-active-font-weight: var(--regular);
  --fc-textarea-max-height: 20rem;
  --fc-textarea-resize: auto;
}

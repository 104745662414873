:root {
  // TODO: rename -> datagrid
  --table-background: var(--white);
  --table-body-border-width: 1px;
  --table-body-border-color: var(--gray--border);
  --table-body-border-radius: 0.25rem;
  --table-header-background: var(--gray--inactive);
  --table-header-border-width: 1px;
  --table-header-border-color: var(--gray--border);
  --table-header-border-radius: 0.25rem;
  --table-row-border-width: 1px;
  --table-row-border-color: var(--gray--border);
  --table-row-stripe-color: var(--white);
  --table-cell-padding-x: 0.75rem;
  --table-cell-padding-y: 0.5rem;
}

@mixin focus {
  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 0px var(--focus-width, 3px)
        var(--focus-color, var(--warn)),
      0px 0px 0px var(--focus-outer-width, 0px)
        var(--focus-outer-color, var(--warn));
    border-radius: var(--focus-border-radius, 4px);
  }
}

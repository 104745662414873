@import '../mixins/responsive';
/* stylelint-disable */

body {
  // sass-lint:disable-block no-vendor-prefixes
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga' 1;
  font-variant-numeric: var(--font-variant-numeric, normal);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

body,
button,
img,
input,
select,
textarea,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: var(--text--1);
}

a {
  cursor: pointer;
  text-decoration: none;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  transition: color var(--transition-timing) ease;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: var(--text--1);
  font-weight: var(--regular);
}

h1,
h2,
h3 {
  font-size: var(--t-mobile-header-font-size);
  line-height: var(--t-mobile-header-line-height);
}

h4,
h5 {
  font-size: var(--t-mobile-subheader-font-size);
  line-height: var(--t-mobile-subheader-line-height);
}

h1 {
  font-family: var(--h1-font-family, var(--typeface-1-name));
  font-weight: var(--h1-font-weight);
  color: var(--h1-font-color);

  @include responsive(small) {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
  }
}

h2 {
  font-family: var(--h2-font-family, var(--typeface-1-name));
  font-weight: var(--h2-font-weight);
  color: var(--h2-font-color);

  @include responsive(small) {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
  }
}

h3 {
  font-family: var(--h3-font-family, var(--typeface-1-name));
  font-weight: var(--h3-font-weight);
  color: var(--h3-font-color);

  @include responsive(small) {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
  }
}

h4 {
  font-family: var(--h4-font-family, var(--typeface-1-name));
  font-weight: var(--h4-font-weight);
  color: var(--h4-font-color);

  @include responsive(small) {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
  }
}

h5 {
  font-family: var(--h5-font-family, var(--typeface-1-name));
  font-weight: var(--h5-font-weight);
  color: var(--h5-font-color);

  @include responsive(small) {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
  }
}

h6 {
  font-size: var(--t-title-font-size);
  line-height: var(--t-title-line-height);
  font-family: var(--h6-font-family, var(--typeface-1-name));
  font-weight: var(--h6-font-weight);
  color: var(--h6-font-color);

  @include responsive(small) {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
  }
}

p,
a,
input,
label {
  font-family: var(--t-data-font-family, var(--typeface-1-name));
  font-size: var(--t-data-font-size);
  line-height: var(--t-data-line-height);
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

pre {
  font-size: 1rem;
  line-height: 1.125rem;
  white-space: pre-wrap;
}

code {
  margin: 0 0.0625rem;
  padding: 0 0.25rem;
  background-color: var(--gray--inactive);
  border-radius: 0.25rem;
  border: 1px solid var(--gray--border);
}

.t-data,
%t-data {
  font-family: var(--t-data-font-family, var(--typeface-1-name));
  font-size: var(--t-data-font-size);
  line-height: var(--t-data-line-height);
}

.t-title,
%t-title {
  font-size: var(--t-title-font-size);
  line-height: var(--t-title-line-height);
}

.t-caption,
%t-caption {
  font-size: var(--t-caption-font-size);
  line-height: var(--t-caption-line-height);
}

.t-link,
%t-link,
p > a {
  color: var(--link);
  cursor: pointer;
  font-weight: var(--t-link-font-weight);
  text-decoration: var(--t-link-text-decoration, none);

  &:hover {
    text-decoration: underline;
  }
}

.t-bold,
%t-bold {
  font-weight: var(--t-bold-font-weight);
}

.t-alt,
%t-alt {
  color: var(--text--2);
}

.t-regular,
%t-regular {
  font-weight: var(--regular);
}

.t-caps,
%t-uppercase {
  text-transform: uppercase;
}

.t-capitalize,
%t-capitalize {
  text-transform: capitalize;
}

.t-ellipsis,
%t-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Inner HTML: have to use class instead of placeholder for ViewEncapsulation
.t-html {
  word-break: break-word;

  a {
    color: var(--link);
    font-weight: var(--t-link-font-weight);

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style-position: inside;
    margin-left: 1.25rem;
  }

  li {
    text-indent: -1.5rem;
    margin-left: 1.5rem;
  }

  @include responsive(small) {
    li {
      margin-bottom: 1rem;
    }
  }
}

/*********************************
**  Semantic Font Combinations  **
*********************************/

// e.g. demographics splash title
%ui-title,
.ui-title {
  color: var(--ui-title-font-color, var(--text--1));
  font-family: var(--ui-title-font-family, var(--typeface-1-name));
  font-weight: var(--ui-title-font-weight, var(--t-bold-font-weight));
  font-size: var(--ui-title-font-size-mobile, var(--t-mobile-header-font-size));
  line-height: var(
    --ui-title-line-height-mobile,
    var(--t-mobile-header-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-title-font-size-desktop, var(--h2-font-size));
    line-height: var(--ui-title-line-height-desktop, var(--h2-line-height));
  }
}
%Subsection-title,
.Subsection-title {
  font-size: var(--t-title-font-size);
  line-height: var(--t-title-line-height);
  font-family: var(--h6-font-family, var(--typeface-1-name));
  font-weight: var(--h6-font-weight);
  color: var(--h6-font-color);

  @include responsive(small) {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
  }
}

//e.g. plan-selection page headers
%ui-title-1,
.ui-title-1 {
  color: var(--ui-title-1-font-color, var(--text--1));
  font-family: var(--ui-title-1-font-family, var(--typeface-1-name));
  font-weight: var(--ui-title-1-font-weight, var(--t-bold-font-weight));
  font-size: var(
    --ui-title-1-font-size-mobile,
    var(--t-mobile-header-font-size)
  );
  line-height: var(
    --ui-title-1-line-height-mobile,
    var(--t-mobile-header-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-title-1-font-size-desktop, var(--h2-font-size));
    line-height: var(--ui-title-1-line-height-desktop, var(--h2-line-height));
  }
}

// e.g. workflow step title
%ui-header,
.ui-header {
  color: var(--ui-header-color, var(--text--1));
  font-family: var(--ui-header-font-family, var(--typeface-1-name));
  font-weight: var(--ui-header-font-weight, var(--t-bold-font-weight));
  font-size: var(
    --ui-header-font-size-mobile,
    var(--t-mobile-subheader-font-size)
  );
  line-height: var(
    --ui-header-line-height-mobile,
    var(--t-mobile-subheader-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-header-font-size-desktop, var(--h4-font-size));
    line-height: var(--ui-header-line-height-desktop, var(--h4-line-height));
  }
}

// e.g. accordion header
%ui-subheader,
.ui-subheader {
  color: var(--ui-subheader-font-color, var(--text--1));
  font-family: var(--ui-subheader-font-family, var(--typeface-1-name));
  font-weight: var(--ui-subheader-font-weight, var(--t-bold-font-weight));
  font-size: var(
    --ui-subheader-font-size-mobile,
    var(--t-mobile-subheader-font-size)
  );
  line-height: var(
    --ui-subheader-line-height-mobile,
    var(--t-mobile-subheader-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-subheader-font-size-desktop, var(--h4-font-size));
    line-height: var(--ui-subheader-line-height-desktop, var(--h4-line-height));
  }
}

// e.g. workflow form card header
%ui-card-header-1,
.ui-card-header-1 {
  color: var(--ui-card-header-1-font-color, var(--text--1));
  font-family: var(--ui-card-header-1-font-family, var(--typeface-1-name));
  font-weight: var(--ui-card-header-1-font-weight, var(--t-bold-font-weight));
  font-size: var(--ui-card-header-1-font-size-mobile, var(--t-title-font-size));
  line-height: var(
    --ui-card-header-1-line-height-mobile,
    var(--t-title-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-card-header-1-font-size-desktop, var(--h6-font-size));
    line-height: var(
      --ui-card-header-1-line-height-desktop,
      var(--h6-line-height)
    );
  }
}

// e.g. plan card title
%ui-card-header-2,
.ui-card-header-2 {
  color: var(--ui-card-header-2-font-color, var(--text--1));
  font-family: var(--ui-card-header-2-font-family, var(--typeface-1-name));
  font-weight: var(--ui-card-header-2-font-weight, var(--t-bold-font-weight));
  font-size: var(--ui-card-header-2-font-size-mobile, var(--t-title-font-size));
  line-height: var(
    --ui-card-header-2-line-height-mobile,
    var(--t-title-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-card-header-2-font-size-desktop, var(--h6-font-size));
    line-height: var(
      --ui-card-header-2-line-height-desktop,
      var(--h6-line-height)
    );
  }
}

// e.g. any description below a header
%ui-description,
.ui-description {
  color: var(--ui-description-color, var(--text--1));
  font-family: var(--ui-description-font-family, var(--typeface-1-name));
  font-weight: var(--ui-description-font-weight, var(--regular));
  font-size: var(--ui-description-font-size-mobile, var(--t-data-font-size));
  line-height: var(
    --ui-description-line-height-mobile,
    var(--t-data-line-height)
  );

  @include responsive(small) {
    font-size: var(--ui-description-font-size-desktop, var(--t-data-font-size));
    line-height: var(
      --ui-description-line-height-desktop,
      var(--t-data-line-height)
    );
  }
}

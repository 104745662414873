/* stylelint-disable plugin/selector-bem-pattern */
:root {
  --status-border-radius: 0.8125rem;
  --status-border-width: 1px;
  --ui-status-color: var(--text--1);
  --ui-status-font-family: var(--typeface-1-name);
  --ui-status-font-weight: var(--regular);
  --ui-status-font-size-desktop: var(--t-data-font-size);
  --ui-status-line-height-desktop: var(
    --ui-status-line-height-desktop,
    var(--t-data-line-height, 1.5rem)
  );

  /**
     @deprecated, use `var(--ui-status-color)` instead
     */
  --status-text: var(--text--1);
}
